var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Row",
        [
          _c(
            "Col",
            { attrs: { span: "12" } },
            [
              _c("apexchart", {
                ref: "memoryInfo",
                attrs: {
                  type: "area",
                  height: "350",
                  options: _vm.memory.chartOptions,
                  series: _vm.memory.series,
                },
              }),
            ],
            1
          ),
          _c(
            "Col",
            { attrs: { span: "12" } },
            [
              _c("apexchart", {
                ref: "keySize",
                attrs: {
                  type: "area",
                  height: "350",
                  options: _vm.key.chartOptions,
                  series: _vm.key.series,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("Divider", { attrs: { orientation: "left" } }, [
        _vm._v("Redis详细信息"),
      ]),
      _c("Table", {
        attrs: {
          columns: _vm.columns,
          data: _vm.redisInfo,
          "show-header": false,
          size: "small",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }